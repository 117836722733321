import React, { useState } from "react";
import Head from "next/head";
import { Container, Logo, Title } from "@clubofcode/ui";

import { Link } from "../components/Link";

const links = [
  ["Impressum", "/legal/imprint"],
  ["Datenschutz", "/legal/privacy/website"],
  ["Rechtliches", "/legal"],
];

const IndexPage = () => {
  const [thisYear] = useState(() => new Date().getFullYear());

  return (
    <div className="relative min-h-screen flex flex-col">
      <Head>
        <title>Club of Code bedankt sich.</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Container className="pt-[17.5vh] flex-grow">
        <div className="flex justify-center items-center">
          <div className="text-center">
            <Title as="h1" className="mb-4">
              <Link to="/" className="block">
                <div className="hidden sm:block">
                  <Logo gradientId="logo-with_text" height={48} withText />
                </div>
                <div className="block sm:hidden">
                  <Logo
                    gradientId="logo-no_text"
                    height={48}
                    withText={false}
                  />
                </div>
              </Link>
            </Title>
            <p className="text-lg text-gray-700 leading-relaxed">
              Wir bedanken uns für die gemeinsame Reise, das Vertrauen und die
              Unterstützung.
              <br />
              Wir wünschen ein erfolgreiches Jahr 2025.
            </p>
          </div>
        </div>
      </Container>
      <footer>
        <div className="bg-gray-100 border-t border-gray-200">
          <div className="container mx-auto px-2 sm:px-0 sm:w-9/10 sm:flex sm:justify-between py-2">
            <div className="block text-center md:text-left mb-1 sm:mb-0">
              &#169; {thisYear} <Link to="/">Club of Code</Link> - Alle Rechte
              vorbehalten.
            </div>
            <ul className="flex justify-center gap-x-8">
              {links.map(([title, target]) => (
                <li key={target} className="">
                  <Link to={target} className="hover:underline">
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default IndexPage;
